import React, { useEffect, useState, Fragment } from 'react';
import Menu from './HeaderComponents/Menu';
import Switch from './HeaderComponents/Switch';
import Translation from './HeaderComponents/Translation';
import Hamburger from './HeaderComponents/Hamburger'
import './header.scss';

const Header = ({ onToggle, theme, triggerTranslation }) => {

    const [scroll, setScroll] = useState(0)
    const [mobileView, setMobileView] = useState(window.innerWidth < 891 ? true : false)
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [optionsClass, setOptionsClass] = useState(window.innerWidth < 891 ? "options transition" : "options");
    let height = scroll === 0 ? '80px' : '60px';
    let transparency = scroll === 0 ? "transparent" : "";

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', onWindowResize)
    }, [])

    const menuOpen = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    const onWindowResize = () => {
        setIsMenuOpen(false)
        if (window.innerWidth < 891) {
            setMobileView(true);
        } else {
            setMobileView(false);
        }
        setOptionsClass("options")
        setTimeout(() => {
            setOptionsClass("options transition")
        }, 500)
    }

    const handleScroll = () => {
        setScroll(window.scrollY)
    }

    const backgroundClick = () => {
        setIsMenuOpen(false);
    }

    return (
        <Fragment>
            <header className="header" style={{ height: height, background: transparency }}>
                <Menu triggerTranslation={triggerTranslation} theme={theme} toggler={onToggle} isMobileView={mobileView} backgroundClick={backgroundClick} isMenuOpen={isMenuOpen} />
                <Hamburger isMobileView={mobileView} isMenuOpen={isMenuOpen} menuOpen={menuOpen} />
                {window.innerWidth > 890 ? <div className={isMenuOpen ? 'options transition open' : optionsClass}>
                    <Switch onToggle={onToggle} theme={theme} />
                    {/* <Mode onToggle={onToggle} theme={theme} /> */}
                    <Translation triggerTranslation={triggerTranslation} />
                </div> : null }
            </header>
        </Fragment>
    )
}

export default Header;