import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import Sun from '../../../../icons/switch/light.svg';
import Moon from '../../../../icons/switch/moon.svg'
import './switch.scss';

const Switch = ({ onToggle, theme }) => {
    const [isToggled, setToggle] = useState(theme === 'dark' ? true : false);

    const onToggleSwitch = () => {
        onToggle();
        setToggle(!isToggled)
    }

    return (
        <label htmlFor='switch' className='switch'>
            <input id="switch" className='switch-input' defaultChecked={isToggled} type="checkbox" onClick={onToggleSwitch} />
            <span className='switch-span'>
                <div className='switch-image switch-dark'>
                    <ReactSVG src={Moon} />
                </div>
                <div className='switch-image switch-light'>
                    <ReactSVG src={Sun} />
                </div>
            </span>
            <span  className='switch-label'>Switch</span> {/* Dodaj etykietę wokół kontrolki */}
        </label>
    )
}

export default Switch;