import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import About from "./About";
import Program from "./Program";
import OCommittee from "./OCommittee";
import SCommittee from "./SCommittee";
import Articles from "./Articles";
import "./archives.nav.scss";

const Archives = ({ setCurrentlySelectedTab, currentlySelectedArchiveYear }) => {
    const { t } = useTranslation('common');

    useEffect(() => {
        window.scrollTo({ top: 0 })
        document.body.classList.add('subpage');
    }, [])

    return (
        <div className="archives-nav">
            <ul className="archives-nav-list">
                <li className="archives-nav-list-element"><button onClick={() => setCurrentlySelectedTab(<About year={currentlySelectedArchiveYear} />)} className="archives-nav-list-element-btn">{t('archives.about')}</button></li>
                <li className="archives-nav-list-element"><button onClick={() => setCurrentlySelectedTab(<Articles year={currentlySelectedArchiveYear} />)} className="archives-nav-list-element-btn">{t('archives.articles')}</button></li>
                <li className="archives-nav-list-element"><button onClick={() => setCurrentlySelectedTab(<Program year={currentlySelectedArchiveYear} />)} className="archives-nav-list-element-btn">{t('archives.program')}</button></li>
                <li className="archives-nav-list-element"><button onClick={() => setCurrentlySelectedTab(<SCommittee year={currentlySelectedArchiveYear} />)} className="archives-nav-list-element-btn">{t('archives.scientificCommittee')}</button></li>
                <li className="archives-nav-list-element"><button onClick={() => setCurrentlySelectedTab(<OCommittee year={currentlySelectedArchiveYear} />)} className="archives-nav-list-element-btn">{t('archives.organizingCommittee')}</button></li>
            </ul>
        </div>
    )
}

export default Archives;