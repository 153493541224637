import { useTranslation } from 'react-i18next';
import './button.scss';

const Button = ({ text, onClick }) => {
    const { t } = useTranslation('common')
    return (
        <button onClick={onClick} className='main-button'>{t(text)}</button>
    )
}

export default Button;