import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion";
import LogoHomepage from "../Logo/LogoHomepage";
import Button from '../Common/Button';
import motionConfig from "../../../utils/motion.config";
import HomeRequest from "../../../Request/home.request";
import './home.scss'

const Home = () => {
    const { t, i18n } = useTranslation('common');
    const [opacity, setOpacity] = useState(0);
    const [position, setPosition] = useState("100px");
    const [textOpacity, setTextOpacity] = useState(0);
    const [pl, setPl] = useState("");
    const [en, setEn] = useState("");
    const [cs, setCs] = useState("");
    const [isData, setIsData] = useState(false);
    const [currentSelectedLanguage, setCurrentSelectedLanguage] = useState("");
    const request = useMemo(() => new HomeRequest(), []);
    const ref = useRef(null);

    const handleCurrentLanguage = useCallback((data = null) => {
        if(!data) {
            if(i18n.language === 'en') {
                setCurrentSelectedLanguage(en)
            } else if (i18n.language === 'pl') {
                setCurrentSelectedLanguage(pl)
            } else if (i18n.language === 'cs') {
                setCurrentSelectedLanguage(cs)
            }
        } else {
            setCurrentSelectedLanguage(data);
        }
    }, [i18n.language, pl, en, cs])

    useEffect(() => {
        window.scrollTo({ top: 0 })
        setOpacity(1)
        window.addEventListener('scroll', handleAnimationIn);
        if (document.body.className === "subpage") {
            document.body.className = "";
        }
    }, [])

    useEffect(() => {
        request.getCurrentInformations().then(res => {
            setIsData(true)
            setPl(res.data[0].pl)
            setEn(res.data[0].en)
            setCs(res.data[0].cs)
            if(i18n.language === 'en') {
                handleCurrentLanguage(res.data[0].en)
            } else if (i18n.language === 'pl') {
                handleCurrentLanguage(res.data[0].pl)
            } else if (i18n.language === 'cs') {
                handleCurrentLanguage(res.data[0].cs)
            }
        }).catch(() => {
            setIsData(false);
        })
        // eslint-disable-next-line
    }, [request, i18n.language])

    useEffect(() => {
        handleCurrentLanguage()
    }, [handleCurrentLanguage])

    const handleAnimationIn = () => {
        if (window.scrollY > 80) {
            setPosition(0);
            setTextOpacity(1);
        }
        else {
            setPosition('100px');
            setTextOpacity(0);
        }
    }

    const scrollToPoint = () => {
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        })
    }

    return (
        <motion.div
            className="homepage"
            variants={motionConfig}
            initial="start"
            animate="anim"
            exit="ending"
            transition={{ ease: 'easeInOut' }}
        >
            <div className="main" style={{ opacity: opacity }}>
                <LogoHomepage className="homepage-logo" />
                <p className="paragraph">{t('homepage.mainText')}</p>
                <Button text="button.learnMore" onClick={scrollToPoint} />
            </div>
            <div id="scrollPath" className="secondary" ref={ref}>
                {isData ?
                <>
                    <h1 style={{ transform: `translateY(${position})`, opacity: textOpacity }}>{t("homepage.secondParagraph")}</h1>
                    <p style={{ transform: `translateY(${position})`, opacity: textOpacity }}>{currentSelectedLanguage}</p>
                </>
                : <></>}
            </div>
        </motion.div>
    )
}

export default Home;