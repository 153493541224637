import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CommentsContext } from '../../../App';
import { useContext, useEffect } from 'react';
import './footer.scss';

const Footer = () => {
    const { t, i18n } = useTranslation('common')
    const { commentsEnabled } = useContext(CommentsContext);

    useEffect(() => {
        i18n.changeLanguage(i18n.language)
    }, [i18n])

    return (
        <>
            <footer>
                <div className='section'>
                    <h1>{t("footer.contact.contactUs")}</h1>
                    <ul>
                        <li><Link to="/contact"><img width={20} height={20} alt='contact' className="prefix" src={require('../../../icons/footer/mail.svg').default} />{t("footer.contact.sendContactMessage")}</Link></li>
                    </ul>
                </div>
                <div className='section'>
                    <h1>{t("footer.ourConference.ourConference")}</h1>
                    <ul>
                        <li><img width={20} height={20} alt='counter' className="prefix" src={require('../../../icons/footer/timer.svg').default} />{t("footer.ourConference.timer")}</li>
                        {commentsEnabled ?
                            <li>
                                <Link to="/comments"><img width={20} height={20} alt='comm' className='prefix' src={require('../../../icons/footer/comment.svg').default} />{t("comments.title")}</Link>
                            </li>
                            :
                            null}
                    </ul>
                </div>
                <div className='section'>
                    <h1>{t("footer.address.address")}</h1>
                    <ul>
                        <li><img width={20} height={20} alt='location' className="prefix" src={require('../../../icons/footer/location.svg').default} />{t("footer.address.conferenceAddress")}</li>
                    </ul>
                </div>
                <div className='section'>
                    <h1>{t("footer.followUs")}</h1>
                    <ul className='socials'>
                        <li><a href="https://www.linkedin.com/school/wsiz-w-bielsku-bialej/?originalSubdomain=pl" target='_blank' rel="noreferrer"><img width={40} height={40} alt='linkedin' src={require("../../../icons/footer/linkedin.svg").default} /></a></li>
                        <li><a href="https://www.facebook.com/WSIZBB" target='_blank' rel="noreferrer"><img width={40} height={40} alt='facebook' src={require("../../../icons/footer/facebook.svg").default} /></a></li>
                        <li><a href="https://www.youtube.com/channel/UCxzyst9oTzTZjC6F2vmAXfg" target='_blank' rel="noreferrer"><img width={40} height={40} alt='youtube' src={require("../../../icons/footer/youtube.svg").default} /></a></li>
                        <li><a href="https://www.instagram.com/biurowsiz/" target='_blank' rel="noreferrer"><img width={40} height={40} alt='instagram' src={require("../../../icons/footer/instagram.svg").default} /></a></li>
                    </ul>
                </div>
            </footer>
            <div className='bottom-line'>
                <p>&#169; Wyższa Szkoła Informatyki i Zarządzania, ul. Legionów 81, 43-300 Bielsko-Biała</p>
            </div>
        </>
    )
}

export default Footer;