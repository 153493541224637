import Request from './request';

class ArticleRequest extends Request {
  constructor() {
    super();
    this.client = super.constructor.getClient();
  }

  getArticles() {
    return this.client.get("articles/");
  }

  getArticle(urlKey) {
    return this.client.get(`articles?article=${urlKey}`)
  }
}

export default ArticleRequest;