import { Fragment, useEffect, useMemo, useState } from 'react';
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { default as Request } from '../../../Request/organizing-committee.request';
import motionConfig from "../../../utils/motion.config";
import './ocommittee.scss';

const OrganizingCommittee = () => {
    const { t } = useTranslation("common");
    const [academicCommitteeContent, setAcademicCommitteeContent] = useState([]);
    const [studentCommitteeContent, setStudentCommitteeContent] = useState([]);
    const [isData, setIsData] = useState(true);
    const request = useMemo(() => new Request(), []);

    useEffect(() => {
        window.scrollTo({ top: 0 });
        document.body.classList.add('subpage');

        setAcademicCommitteeContent([]);
        setStudentCommitteeContent([]);

        request.getOrganizingCommittee().then(res => {
            if (res.data.length <= 0) {
                throw new Error('Content empty');
            }

            // eslint-disable-next-line
            Array.from(res.data).map(item => {
                if (item.committee_member_is_student === true) setStudentCommitteeContent(current => [...current, item]);
                else setAcademicCommitteeContent(current => [...current, item]);
            })
        }).catch(() => {
            setIsData(false);
        })
    }, [request])

    return (
        <div className="ocommittee">
            <motion.div
                className='committee-top-bar'
                variants={motionConfig}
                initial="start"
                animate="anim"
                exit="ending"
                transition={{ ease: 'easeInOut' }}
            >
                <h1>{t("organizingCommittee.menuTitle")}</h1>
            </motion.div>
            <div className="ocommittee-content">
                {isData ?
                    <Fragment>
                        <motion.ul
                            className="ocommittee-content-academic"
                            variants={motionConfig}
                            initial="start"
                            animate="anim"
                            exit="ending"
                            transition={{ ease: 'easeInOut' }}
                        >
                            {academicCommitteeContent
                                .sort((a, b) => a.sort_order - b.sort_order)
                                .map(item => {
                                    return (
                                        <li key={item.id} className="ocommittee-content-academic">
                                            <span className="ocommittee-name">{item.committee_member}</span>
                                            <span className="ocommittee-college">{item.committee_member_college}</span>
                                            <span className="ocommittee-college-city">{item.committee_member_college_city}</span>
                                        </li>
                                    )
                                })}
                        </motion.ul>
                        <motion.div
                            className="ocommittee-content-student"
                            variants={motionConfig}
                            initial="start"
                            animate="anim"
                            exit="ending"
                            transition={{ ease: 'easeInOut' }}
                        >
                            <h1 className="ocommittee-content-student-title">{t("committee.studentCommittee")}</h1>
                            <ul className="ocommittee-content-student-list">
                                {studentCommitteeContent
                                    .sort((a, b) => a.sort_order - b.sort_order)
                                    .map(item => {
                                        return (
                                            <li key={item.id} className="ocommittee-content-student-list-element">{item.committee_member}</li>
                                        )
                                    })}
                            </ul>
                        </motion.div>
                    </Fragment>
                    :
                    <span className="no-data">{t("global.noData")}</span>
                }
            </div>
        </div>
    )
}

export default OrganizingCommittee;