const motionConfig = {
    start: {
        opacity: 0,
        duration: { duration: .05 }
    },
    anim: {
        opacity: 1,
        duration: { duration: .05 }
    },
    ending: {
        opacity: 0,
        duration: { duration: .05 }
    }
}

export default motionConfig;