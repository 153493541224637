import { useEffect, useState } from "react";
import './scroll-button.scss';

const ScrollButton = () => {
    const [opacity, setOpacity] = useState(0);
    const [cursor, setCursor] = useState('default');

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
    }, [])

    const scrollHandler = () => {
        if (window.scrollY > 200) {
            setOpacity(window.innerWidth < 768 ? 0.7 : 1);
            setCursor('pointer');
        } else {
            setOpacity(0);
            setCursor('default');
        }
    }

    const clickHandler = () => {
        if (window.scrollY > 200) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }

    return (
        <div onClick={clickHandler} className="scroll-button" style={{ opacity: opacity, cursor: cursor }}><div className="scroll-button-arrow"></div></div>
    )
}

export default ScrollButton;