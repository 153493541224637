import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { default as Request } from '../../../Request/scientific-committee.request';
import { motion } from 'framer-motion';
import motionConfig from "../../../utils/motion.config";
import './scommittee.scss';

const ScientificCommittee = () => {
    const { t } = useTranslation("common")
    const [committeeContent, setCommitteeContent] = useState([])
    const [isData, setIsData] = useState(true);
    const request = useMemo(() => new Request(), []);

    useEffect(() => {
        window.scrollTo({ top: 0 })
        document.body.classList.add('subpage');
        request.getScientificCommittee().then(res => {
            if (res.data.length <= 0) {
                throw new Error('Content empty')
            }
            setCommitteeContent(res.data);
        }).catch(() => {
            setIsData(false);
        })
    }, [request])

    return (
        <div className="committee">
            <motion.div
                className='committee-top-bar'
                variants={motionConfig}
                initial="start"
                animate="anim"
                exit="ending"
                transition={{ ease: 'easeInOut' }}
            >
                <h1>{t("scientificCommittee.menuTitle")}</h1>
            </motion.div>
            <div className='committee-content'>
                {isData ?
                    <motion.ul
                        variants={motionConfig}
                        initial="start"
                        animate="anim"
                        exit="ending"
                        transition={{ ease: 'easeInOut' }}
                    >
                        {Array
                            .from(committeeContent)
                            .sort((a, b) => a.sort_order - b.sort_order)
                            .map(item => {
                                return (
                                    <li key={item.id} className='committee' index={item.id}>
                                        <span className='committee-member'>{item.committee_member}</span>
                                        <span className='committee-college'>{item.committee_member_college}, {item.committee_member_college_city}</span>
                                    </li>
                                )
                            })}
                    </motion.ul>
                    : <span className='no-data'>{t('global.noData')}</span>
                }
            </div>
        </div>
    )
}

export default ScientificCommittee;