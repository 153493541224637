import LogoFirst from '../../../icons/logo/aic-logo-first.png';
import LogoFirstWebp from '../../../icons/logo/aic-logo-first.webp';
import LogoSecond from '../../../icons/logo/aic-logo-second.png';
import LogoSecondWebp from '../../../icons/logo/aic-logo-second.webp';
import './logonav.scss';

const LogoNav = () => {
    return (
        <div className='logo-nav'>
            <picture>
                <source srcSet={LogoFirstWebp} type="image/webp" />
                <source srcSet={LogoFirst} type="image/png" />
                <img width={50} height={39} alt='logo-nav' className='logo-nav-first' src={LogoFirst} />
            </picture>
            <picture>
                <source srcSet={LogoSecondWebp} type="image/webp" />
                <source srcSet={LogoSecond} type="image/png" />
                <img width={32} height={39} alt='logo-nav' className='logo-nav-second' src={LogoSecond} />
            </picture>
        </div>
    )
}

export default LogoNav