import motionConfig from "../../../utils/motion.config";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ContactRequest from "../../../Request/contact.request";
import './contact.scss';

const Contact = () => {
    const { t } = useTranslation('common');
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState('');
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');

    useEffect(() => {
        window.scrollTo({ top: 0 });
        document.body.classList.add('subpage');
    }, [])

    const handleSendEmail = () => {
        setLoading(true);
        setButtonDisabled(true);
        const request = new ContactRequest();
        request.sendEmail(email, title, message).then(() => {
            setTimeout(() => {
                setLoading(false);
                setStatus("email.success");
                setButtonDisabled(false);
            }, 50)
        }).catch(() => {
            setTimeout(() => {
                setLoading(false);
                setStatus("email.error");
                setButtonDisabled(false);
            }, 50)
        })
    }

    return (
        <div className="contact">
            <motion.div
                className='contact-top-bar'
                variants={motionConfig}
                initial="start"
                animate="anim"
                exit="ending"
                transition={{ ease: 'easeInOut' }}
            >
                <h1>{t("contact.mainTitle")}</h1>
            </motion.div>
            <div className="contact-content">
                <motion.div
                    className="contact-content-main"
                    variants={motionConfig}
                    initial="start"
                    animate="anim"
                    exit="ending"
                    transition={{ ease: 'easeInOut' }}
                >
                    <div className="contact-content-main-card-left  contact-content-main-card">
                        <div className="contact-content-main-card-left-info">
                            <h1>{t("contact.information")}</h1>
                            <p>{t("contact.informationText")}</p>
                            <p>{t("contact.address")}</p>
                            <p>{t("contact.phone")}</p>
                        </div>
                    </div>

                    <div className="contact-content-main-card-right  contact-content-main-card">
                        <h1>{t("contact.contactForm")}</h1>

                        <form>
                            <input type="text" placeholder={t('contact.email')} value={email} onChange={e => setEmail(e.target.value)} />
                            <input type="text" placeholder={t('contact.title')} value={title} onChange={e => setTitle(e.target.value)} />
                            <textarea spellCheck="false" type="text" placeholder={t('contact.message')} value={message} onChange={e => setMessage(e.target.value)} />
                        </form>
                        <div className="info-container">
                            <p>{t(status)}</p>
                        </div>
                        <div className="contact-content-main-card-right-button-container">
                            {loading ? null : <button disabled={buttonDisabled} onClick={() => handleSendEmail()}>{t('contact.send')}</button>}
                            <span className={loading ? "form-loading" : ""}></span>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default Contact;