import Request from './request';

class ArchiveRequest extends Request {
  constructor() {
    super()
    this.client = super.constructor.getClient();
  }

  getArchiveYear() {
    return this.client.get("archive/years");
  }

  getArchiveInformation(year, language = null) {
    if (language) {
      return this.client.get(`archive/information?year=${year}&language=${language}`)
    }
    return this.client.get(`archive/information?year=${year}`)
  }

  getArchivedProgram(year) {
    return this.client.get(`archive/program?year=${year}`)
  }

  getArchivedOrganizingCommittee(year) {
    return this.client.get(`archive/organizing-committee?year=${year}`)
  }

  getArchivedScientificCommittee(year) {
    return this.client.get(`archive/scientific-committee?year=${year}`)
  }

  getArchivedArticles(year) {
    return this.client.get(`archive/articles?year=${year}`)
  }
}

export default ArchiveRequest;