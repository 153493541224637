import { createContext, useEffect, useMemo, useState } from "react";
import { Maintenance } from "./Components/frontend/Maintenance/Maintenance";
import Router from './Components/frontend/Router/Router';
import Cookies from 'universal-cookie';
import CookiesConsent from './Components/frontend/Common/Cookies';
import ConfigRequest from "./Request/config.request";
import './App.scss';

export const ThemeContext = createContext('light')
export const CommentsContext = createContext(false);

function App() {
  const cookies = useMemo(() => new Cookies(), []);
  const [theme, setTheme] = useState(cookies.get('AIC_THEME'));
  const [maintenance, setMaintenance] = useState(cookies.get('aic_maintenance'));
  const [commentsEnabled, setCommentsEnabled] = useState(null);
  const [commentsConfigLoading, setCommentsConfigLoading] = useState(true);

  const request = useMemo(() => new ConfigRequest(), []);

  useEffect(() => {
    request.getMaintenanceMode().then(res => {
      setMaintenance(res.data[0].is_active);
    })
  }, [request]);

  useEffect(() => {
    let data = {
      daily: cookies.get("AIC_DTID") ? cookies.get("AIC_DTID") : null,
      monthly: cookies.get("AIC_MTID") ? cookies.get("AIC_MTID") : null,
      yearly: cookies.get("AIC_YTID") ? cookies.get("AIC_YTID") : null
    };

    if (!data.daily || !data.monthly || !data.yearly) {
      request.createTraffic(data).then(res => {
        if (res.data.daily && res.data.daily_timestamp) {
          const currentTime = new Date().getTime();
          const expirationTime = new Date(res.data.daily_timestamp).getTime();
          const maxAgeSeconds = Math.floor((expirationTime - currentTime) / 1000);

          cookies.set("AIC_DTID", res.data.daily, { path: '/', maxAge: maxAgeSeconds })
        }

        if (res.data.monthly && res.data.monthly_timestamp) {
          const currentTime = new Date().getTime();
          const expirationTime = new Date(res.data.monthly_timestamp).getTime();
          const maxAgeSeconds = Math.floor((expirationTime - currentTime) / 1000);

          cookies.set("AIC_MTID", res.data.monthly, { path: '/', maxAge: maxAgeSeconds })
        }

        if (res.data.yearly && res.data.yearly_timestamp) {
          const currentTime = new Date().getTime();
          const expirationTime = new Date(res.data.yearly_timestamp).getTime();
          const maxAgeSeconds = Math.floor((expirationTime - currentTime) / 1000);

          cookies.set("AIC_YTID", res.data.yearly, { path: '/', maxAge: maxAgeSeconds })
        }
      })
    }
  }, [request, cookies])

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"))
    if (document.body.id === "light") {
      document.body.id = "dark";
      if (cookies.get('AIC_COOKIE_ACC') === "1") {
        cookies.set('AIC_THEME', 'dark', { path: '/', maxAge: 60 * 60 * 24 * 365 })
      }

    } else {
      document.body.id = "light"
      if (cookies.get('AIC_COOKIE_ACC') === "1") {
        cookies.set('AIC_THEME', 'light', { path: '/', maxAge: 60 * 60 * 24 * 365 })
      }
    }
  }

  const handleCookieConsent = () => {
    cookies.set("AIC_COOKIE_ACC", 1, { path: '/', maxAge: 60 * 60 * 24 * 365 })
  }

  useEffect(() => {
    let cookieTheme = cookies.get('AIC_THEME')

    if (cookieTheme !== undefined) {
      setTheme(cookieTheme)
      document.body.id = cookieTheme;
    }
    else {
      document.body.id = 'light';
    }
  }, [cookies])

  useEffect(() => {
    request.getIsCommentsEnabled().then(res => {
      setCommentsEnabled(res.data[0].is_active);
      setCommentsConfigLoading(false);
    })
  }, [request])


  if (maintenance) {
    return (
      <Maintenance />
    )
  } else {
    if (commentsConfigLoading) {
      return null;
    } else {
      return (
        <CommentsContext.Provider value={{ commentsEnabled, setCommentsEnabled }}>
          <ThemeContext.Provider value={{ theme, toggleTheme }}>
            <div className="App">
              <Router onToggle={toggleTheme} theme={theme} currentLanguage={""} />
              <CookiesConsent handleCookieConsent={handleCookieConsent} />
            </div>
          </ThemeContext.Provider>
        </CommentsContext.Provider>
      );
    }
  }
}

export default App;
