import React, { useState, useEffect, useMemo } from 'react';
import { changeLanguage } from 'i18next';
import Cookies from 'universal-cookie';
import './translations.scss';

const pl = require('../../../../icons/translations/pl.svg')
const en = require('../../../../icons/translations/en.svg')
const cz = require('../../../../icons/translations/cz.svg')

const Translation = () => {
    const cookies = useMemo(() => new Cookies(), []);
    const options = useMemo(() => [
        { value: 'pl', label: 'PL', img: pl, index: 0 },
        { value: 'en', label: 'EN', img: en, index: 1 },
        { value: 'cs', label: 'CS', img: cz, index: 2 },
    ], []);
    const [translation, setTranslation] = useState(options[0])

    useEffect(() => {
        const cookieConsent = cookies.get("AIC_COOKIE_ACC")
        const translationValue = cookies.get("AIC_MODE_LANG")

        if (cookieConsent !== undefined) {
            if (translationValue === undefined) {
                cookies.set('AIC_MODE_LANG', 1, { path: '/', maxAge: 60 * 60 * 24 * 365 })
                changeLanguage(options[1].value)
                setTranslation(options[1])
            } else {
                changeLanguage(options[translationValue].value)
                setTranslation(options[translationValue])
            }
        } else {
            changeLanguage(options[1].value)
            setTranslation(options[1])
        }

    }, [cookies, options])

    const handleChange = e => {
        const cookieConsent = cookies.get("AIC_COOKIE_ACC")

        if (cookieConsent !== undefined) {
            cookies.set('AIC_MODE_LANG', e.target.value, { path: '/', maxAge: 60 * 60 * 24 * 365 })
        }
        changeLanguage(options[e.target.value].value)
        setTranslation(options[e.target.value])
    }


    return (
        <div className="translation">
            <div className='translation-selected'>
                <img width={30} height={30} alt='translation' src={translation.img.default} />
            </div>
            <label className='app-core-none' htmlFor="pl">PL</label>
            <label className='app-core-none' htmlFor="en">EN</label>
            <label className='app-core-none' htmlFor="cs">CS</label>
            <select aria-labelledby='pl en cs' value={translation.index} onChange={handleChange}>
                <option id="pl" value="0">PL</option>
                <option id="en" value='1'>EN</option>
                <option id="cs" value='2'>CS</option>
            </select>
        </div>
    )
}

export default Translation;