import Request from './request';

class CommentsRequest extends Request {
  constructor() {
    super();
    this.client = super.constructor.getClient();
  }

  addComment(body) {
    return this.client.post("comments", body)
  }

  getComments() {
    return this.client.get("comments")
  }
}

export default CommentsRequest;