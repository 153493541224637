import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from "framer-motion";
import motionConfig from '../../../utils/motion.config';
import ArticleRequest from '../../../Request/article.request';
import { useTranslation } from 'react-i18next';
import './article.scss';

const Article = () => {
    const [article, setArticle] = useState(null);
    const {articleName, year} = useParams();
    const { t } = useTranslation('common');

    useEffect(() => {
        window.scrollTo({ top: 0 })
        document.body.classList.add('subpage');
    }, [])

    useEffect(() => {
        const request = new ArticleRequest();
        request.getArticle(articleName).then(res => {
            setArticle(res.data[0]);
        })
    }, [articleName])

    return (
        <div className='single-article'>
            <motion.div
                className='single-article-top-bar'
                variants={motionConfig}
                initial="start"
                animate="anim"
                exit="ending"
                transition={{ ease: 'easeInOut' }}
            >
                {article ? <h1>{t("archives.menuTitle")} {year}</h1> : null}
            </motion.div>
            <motion.div className='single-article-content'>
                <motion.div
                    className='single-article-wrapper'
                    variants={motionConfig}
                    initial="start"
                    animate="anim"
                    exit="ending"
                    transition={{ ease: 'easeInOut' }}>
                    <div className='single-article-authors'>
                        {article ? article.authors.split(';').map(author => {
                            return (
                                <span className='single-article-author'>{author}</span>
                            )
                        }) : null}
                    </div>
                    <span className='single-article-title'>{article ? article.title : null}</span>
                    <span className='single-article-description'>{article ? article.description : null}</span>
                </motion.div>
            </motion.div>
        </div>
    )
}

export default Article;