import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Home from "../Home/Home";
import Header from "../Header/Header";
import Articles from '../Articles/Articles';
import ScientificCommittee from '../ScientificCommittee/ScientificCommittee';
import OrganizingCommittee from '../OrganizingCommittee/OrganizingCommittee';
import Contact from '../Contact/Contact';
import Archives from '../Archives/Archives';
import Footer from "../Footer/Footer";
import Program from "../WorkshopProgram/Program";
import ScrollButton from "../Common/ScrollButton";
import Article from "../Articles/Article";
import NotFound from '../NotFound/NotFound';
import Comments from '../Comments/Comments';

const Router = ({ onToggle, theme, triggerTranslation }) => {
    const location = useLocation();
    return (
        <>
            <Header onToggle={onToggle} theme={theme} triggerTranslation={triggerTranslation} />
            <AnimatePresence mode="wait">
                <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<Home />} />
                    <Route path="/articles" element={<Articles />} />
                    <Route path="/workshop-program" element={<Program />} />
                    <Route path="/scientific-committee" element={<ScientificCommittee />} />
                    <Route path="/organizing-committee" element={<OrganizingCommittee />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="archive/:year" element={<Archives />} />
                    <Route path="archive/:year/articles/:articleName" element={<Article />} />
                    <Route path="/articles/:articleName" element={<Article />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/comments" element={<Comments />} />
                </Routes>
            </AnimatePresence>
            <Footer />
            <ScrollButton />
        </>
    )
}

export default Router;