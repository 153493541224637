import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './search.scss'

const Search = ({ inputHandler, onSearchSuccess, articlesToSearch }) => {
    const { t } = useTranslation('common');
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        setArticles(articlesToSearch);
    }, [articlesToSearch]);

    const handleSearch = e => {
        inputHandler(e.target.value)
        let articlesArray = [];
        // eslint-disable-next-line
        articles.map(article => {
            if (article.title.toLowerCase().includes(e.target.value.toLowerCase())) {
                articlesArray.push(article);
            }
        })
        if (articlesArray.length === articles.length) {
            onSearchSuccess([])
            return;
        }
        onSearchSuccess(articlesArray)
    }

    return (
        <div className="search">
            <input onInput={handleSearch} type="text" id='search-input' placeholder={t("search")} />
            <button><img alt='search' src={require('../../../icons/base/search.svg').default} /></button>
        </div>
    )
}

export default Search;