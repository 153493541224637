import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { default as WorkshopProgram } from "../../../Request/program.request";
import './program.scss';
import motionConfig from "../../../utils/motion.config";

const Program = () => {
    const { t } = useTranslation('common');
    const request = new WorkshopProgram();
    const [programData, setProgramData] = useState([]);
    const [isData, setIsData] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo({ top: 0 });
        document.body.classList.add('subpage');
        request.getWorkshopProgram().then(res => {
            if (res.data.length <= 0) {
                throw new Error('Content empty');
            }
            setProgramData(res.data);
            setLoading(false);
        }).catch(() => {
            setIsData(false);
            setLoading(false);
        })
        // eslint-disable-next-line
    }, [])

    return (
        <div className="workshop-program">
            <div className="workshop-program-top"><motion.h1
                variants={motionConfig}
                initial="start"
                animate="anim"
                exit="ending"
                transition={{ ease: 'easeInOut' }}
            >{t('workshopProgram.menuTitle')}</motion.h1></div>
            <ul className={programData.length <= 0 ? "workshop-program-main workshop-program-main--no-data" : "workshop-program-main"}>
                {!isData ? <span className="no-data">{t('global.noData')}</span> : <></>}
                {loading ? <div></div> : Array
                    .from(programData)
                    .sort((a, b) => a.sort_order - b.sort_order)
                    .map(programElement => {
                    return (
                        <motion.li
                            key={programElement.id}
                            className="workshop-program-element"
                            variants={motionConfig}
                            initial="start"
                            animate="anim"
                            exit="ending"
                            transition={{ ease: 'easeInOut' }}
                        >
                            <div className="workshop-program-element-time">{programElement.program_hour}</div>
                            <div className="workshop-program-element-content">
                                <span className="workshop-program-element-top-title">{programElement.program_title}</span>
                                <div className="workshop-program-element-bottom">
                                    {programElement.program_presenters?.split(';').map((programPresenters, idx) => {
                                        return (
                                            <div key={idx} className="workshop-program-element-bottom-element">
                                                <div className="workshop-program-element-bottom-element-presenter">{programPresenters}</div>
                                                <div className="workshop-program-element-bottom-element-presenter-description">{programElement.program_presenters_description?.split(';')[idx]}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </motion.li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Program;