import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import './cookies.scss';

const CookiesConsent = ({ handleCookieConsent }) => {
    const cookies = useMemo(() => new Cookies(), []);
    const { t } = useTranslation('common')
    const [isCookiesBannerNeeded, setIsCookieBannerNeeded] = useState(false);
    const [cookiesClass, setCookiesClass] = useState("cookie-consent");

    useEffect(() => {
        const consent = cookies.get('AIC_COOKIE_ACC');
        if (parseInt(consent) !== 1) {
            setIsCookieBannerNeeded(true);
        }
    }, [cookies])

    const handleClickCookieConsent = () => {
        handleCookieConsent();
        setIsCookieBannerNeeded(false);
        setCookiesClass('cookie-consent hidden');
    }

    if (isCookiesBannerNeeded) {
        return (
            <div className={cookiesClass}>
                <div className="cookie-container">
                    <p className="cookie-text">{t("cookies.text")}</p>
                    <button className="cookie-accept-button" onClick={() => handleClickCookieConsent()}>{t("cookies.button")}</button>
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default CookiesConsent;