import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import common_en from "./translations/en/common.json";
import common_pl from "./translations/pl/common.json";
import common_cs from "./translations/cs/common.json";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

let getSelectedLanguage = () => {
  switch (cookies.get('AIC_MODE_LANG')) {
    case '0':
      return 'pl';
    case '1':
      return 'en';
    case '2':
      return 'cs';
    default:
      return 'en';    
  }
}

i18next.init({
  interpolation: { escapeValue: false },
  lng: getSelectedLanguage(),
  resources: {
    en: {
      common: common_en
    },
    pl: {
      common: common_pl
    },
    cs: {
      common: common_cs
    }
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </BrowserRouter>
);
