import Request from './request';

class Program extends Request {
  constructor() {
    super();
    this.client = super.constructor.getClient();
  }

  getWorkshopProgram() {
    return this.client.get("program/");
  }
}

export default Program;