import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import motionConfig from "../../../utils/motion.config";
import ArchiveRequest from "../../../Request/archive.request";
import './program.scss';

const Program = ({ year }) => {
    const [data, setData] = useState();
    const { t } = useTranslation('common');

    useEffect(() => {
        const request = new ArchiveRequest();
        if (year === undefined) {
            // eslint-disable-next-line
            year = window.location.pathname.split("/").slice(-1)[0];
        }
        request.getArchivedProgram(year).then(res => {
            setData(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <motion.div
            className="archive-program"
            variants={motionConfig}
            initial="start"
            animate="anim"
            exit="ending"
            transition={{ ease: 'easeInOut' }}
        >
            <ul className="list">
                {data ? (data.map((data, idx) => {
                    return (
                        <li key={data.id} className="list-element">
                            <div className="list-element-hour">{data.program_hour}</div>
                            <div className="list-element-content">
                                <span className="list-element-content-title">{data.program_title}</span>
                                <div className="list-element-content-container">
                                    {/* eslint-disable-next-line */}
                                    {data.program_presenters ? data.program_presenters.split(";").map((presenter, idx) => {
                                        if (presenter !== '') {
                                            return (
                                                <div key={idx} className="list-element-content-container-box">
                                                    <p className="list-element-content-presenter">{presenter}</p>
                                                    <p className="list-element-content-description">{data.program_presenters_description ? data.program_presenters_description.split(";")[idx] : null}</p>
                                                </div>
                                            )
                                        }
                                    }) : null}
                                </div>
                            </div>
                        </li>
                    )
                })) : <span className="list--no-data">{t("global.noData")}</span>}
            </ul>
        </motion.div>
    )
}

export default Program;