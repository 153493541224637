import Request from './request';

class ConfigRequest extends Request {
  constructor() {
    super();
    this.client = super.constructor.getClient();
  }

  getMaintenanceMode() {
    return this.client.get("config/maintenance");
  }

  getIsCommentsEnabled() {
    return this.client.get("config/comments");
  }

  getArticlesPerPage() {
    return this.client.get("config/articles");
  }

  getIsArchivedArticlesVisible() {
    return this.client.get("config/archived-articles");
  }

  createTraffic(data) {
    return this.client.post("/traffic", data)
  }
}

export default ConfigRequest;