import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { CommentsContext } from '../../../App';
import motionConfig from '../../../utils/motion.config';
import AddComment from './AddComment';
import CommentsRequest from '../../../Request/comments.request';
import './comments.scss';

const Comments = () => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [opacity, setOpacity] = useState(0);
    const [comments, setComments] = useState([]);
    const { commentsEnabled } = useContext(CommentsContext);

    useEffect(() => {
        window.scrollTo({ top: 0 })
        document.body.classList.add('subpage');
    }, [])

    useEffect(() => {
        const request = new CommentsRequest();
        request.getComments().then(res => {
            setComments(res.data);
        })
    }, [])

    useEffect(() => {
        if(!commentsEnabled) {
            navigate("/404");
        }
    }, [commentsEnabled, navigate])

    const handleCommentClose = () => setOpacity(0);

    return (
        <div className='comments'>
            <motion.div
                className='comments-top-bar'
                variants={motionConfig}
                initial="start"
                animate="anim"
                exit="ending"
                transition={{ ease: 'easeInOut' }}>
                <h1>{t("comments.title")}</h1>
            </motion.div>
            <div className='comments-main'>
                <AddComment changeOpacity={() => setOpacity(0)} closePopup={handleCommentClose} opacity={opacity} />
                <motion.div
                    className='comments-wrapper'
                    variants={motionConfig}
                    initial="start"
                    animate="anim"
                    exit="ending"
                    transition={{ ease: 'easeInOut' }}
                >
                    <button className='comments-add-comment-show' onClick={() => setOpacity(1)}>{t("comments.add_comment")}</button>
                    <ul className='comments-list'>
                        {comments ? comments.map(comment => {
                            return (
                                <li key={comment.id} className='comments-comment'>
                                    <div className='top-bar'>
                                        <span className='author'>{comment.comment_author}</span>
                                        <span className='time'>{comment.comment_time}</span>
                                    </div>
                                    <div className='content-wrapper'>
                                        <span className='content'>{comment.comment}</span>
                                    </div>
                                </li>
                            )
                        }) : <></>}
                    </ul>
                </motion.div>
            </div>
        </div>
    )
}

export default Comments;