import Request from './request';

class ContactRequest extends Request {
  constructor() {
    super();
    this.client = super.constructor.getClient();
  }

  sendEmail(email, title, content) {
    return this.client.post("smtp/contact", {
        email: email,
        title: title,
        content: content
    });
  }
}

export default ContactRequest;