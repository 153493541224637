import React, { useEffect, useState, Fragment } from "react";
import motionConfig from "../../../utils/motion.config";
import { motion } from "framer-motion";
import ArchiveRequest from "../../../Request/archive.request";
import { useTranslation } from 'react-i18next';
import "./ocommittee.scss";

const OCommittee = ({ year }) => {
    const [committee, setCommittee] = useState(null);
    const [isData, setIsData] = useState(false);
    const { t } = useTranslation('common');

    useEffect(() => {
        const request = new ArchiveRequest();
        if (year === undefined) {
            // eslint-disable-next-line
            year = window.location.pathname.split("/").slice(-1)[0];
        }
        request.getArchivedOrganizingCommittee(year).then(res => {
            setIsData(res.data.length <= 0 ? false : true);
            setCommittee(res.data);
        }).catch(() => setIsData(false));
    }, [])

    return (
        <motion.div
            className="archive-organizing-committee"
            variants={motionConfig}
            initial="start"
            animate="anim"
            exit="ending"
            transition={{ ease: 'easeInOut' }}
        >
            {isData ? <></> : <span className="no-data">{t('global.noData')}</span>}
            {committee && isData ?
                <div className="archive-organizing-committee-container">
                    <ul className="list">
                        {(committee.map(item => {
                            return (item.committee_member_is_student ?
                                <Fragment key={item.id} />
                                :
                                <li key={item.id} className="list-element archive-organizing-committee-list-element">
                                    <span className="member">{item.committee_member}</span>
                                    <span className="college">{item.committee_member_college}</span>
                                    <span className="city">{item.committee_member_college_city}</span>
                                </li>
                            )
                        }))}
                    </ul>
                    <div className="student">
                        <span className="title">{t('committee.studentCommittee')}</span>
                        <ul className="student-list">
                            {committee.map(item => {
                                return (item.committee_member_is_student ?
                                    <li key={item.id} className="list-element archive-organizing-committee-list-element">
                                        <span className="member">{item.committee_member}</span>
                                    </li>
                                    :
                                    <Fragment key={item.id} />)
                            })}
                        </ul>
                    </div>
                </div> : <Fragment />}
        </motion.div>
    )
}


export default OCommittee;