import Request from './request';

class FunFactRequest extends Request {
  constructor() {
    super();
    this.client = super.constructor.getClient();
  }

  getFunFacts() {
    return this.client.get("funfact/");
  }
}

export default FunFactRequest;