import Request from './request';

class HomeRequest extends Request {
  constructor() {
    super();
    this.client = super.constructor.getClient();
  }

  getCurrentInformations() {
    return this.client.get("information/");
  }
}

export default HomeRequest;