import React, { useEffect, useState, useCallback, useMemo } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import motionConfig from "../../../utils/motion.config";
import ArchiveRequest from "../../../Request/archive.request";
import Cookies from 'universal-cookie';
import './about.scss';

const About = ({ year }) => {
    const request = useMemo(() => new ArchiveRequest(), []);
    const cookies = useMemo(() => new Cookies(), []);
    const [pl, setPl] = useState('');
    const [cs, setCs] = useState('');
    const [en, setEn] = useState('');
    const [content, setContent] = useState('');
    const { t, i18n } = useTranslation('common');

    useEffect(() => {
        let langId = cookies.get('AIC_MODE_LANG');
        if (langId === 0) { setContent(pl) }
        if (langId === 1 || langId === undefined) { setContent(en) }
        if (langId === 2) { setContent(cs) }
    }, [cookies, cs, en, pl]);

    const handleLanguageChanged = useCallback(() => {
        request.getArchiveInformation(year, i18n.language).then(res => {
            try {
                setContent(Object.values(res.data[0])[0])
            } catch (e) {}
        })
    }, [i18n.language, year, request]);

    useEffect(() => {
        i18n.on('languageChanged', handleLanguageChanged);
        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        }
    }, [handleLanguageChanged, i18n])

    useEffect(() => {
        if (year === undefined) {
            // eslint-disable-next-line
            year = window.location.pathname.split("/").slice(-1)[0];
        }
        request.getArchiveInformation(year).then(res => {
            let data = res.data[0];
            let langId = parseInt(cookies.get("AIC_MODE_LANG"));

            setPl(data.pl)
            setCs(data.cs)
            setEn(data.en)

            if (langId === 0) { setContent(data.pl) }
            if (langId === 1 || langId === undefined) { setContent(data.en) }
            if (langId === 2) { setContent(data.cs) }
        }).catch(() => {
            setContent(t("global.noData"))
        })
    }, [])

    return (
        <motion.div
            className="archives-about"
            variants={motionConfig}
            initial="start"
            animate="anim"
            exit="ending"
            transition={{ ease: 'easeInOut' }}
        >
            <p className="archives-about-content">
                {content}
            </p>
        </motion.div>
    )
}

export default About;