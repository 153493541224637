import axios from "axios";

class Request {
    static getClient() {
        return axios.create({
            baseURL: process.env.NODE_ENV === 'production' ? '/api/V1' : 'http://localhost:8080/api/V1',
            timeout: 4000,
            headers: { 'webapikey': '99607d89-038a-4306-b090-df3cfcecddf0' }
        })
    }
}

export default Request;