import Request from './request';

class ScientificCommittee extends Request {
  constructor() {
    super();
    this.client = super.constructor.getClient();
  }

  getScientificCommittee() {
    return this.client.get("scommittee/");
  }
}

export default ScientificCommittee;