import React, { useEffect, useState, Fragment } from "react";
import { motion } from "framer-motion";
import motionConfig from "../../../utils/motion.config";
import ArchiveRequest from "../../../Request/archive.request";
import { useTranslation } from 'react-i18next';
import './scommittee.scss';

const SCommittee = ({ year }) => {
    const [committee, setCommittee] = useState(null);
    const [isData, setIsData] = useState(false);
    const { t } = useTranslation('common');

    useEffect(() => {
        const request = new ArchiveRequest();
        if (year === undefined) {
            // eslint-disable-next-line
            year = window.location.pathname.split("/").slice(-1)[0];
        }
        request.getArchivedScientificCommittee(year).then(res => {
            setIsData(res.data.length <= 0 ? false : true);
            setCommittee(res.data)
        }).catch(() => {
            setIsData(false);
        })
    }, [])

    return (
        <motion.div
            className="archive-scientific-committee"
            variants={motionConfig}
            initial="start"
            animate="anim"
            exit="ending"
            transition={{ ease: 'easeInOut' }}
        >
            {isData ? <Fragment /> : <span className="no-data">{t('global.noData')}</span>}
            <ul className="list">
                {committee ? (committee.map(data => {
                    return (
                        <li className="list-element" key={data.id}>
                            <span className="list-element-member element-text">{data.committee_member}</span>
                            <span className="list-element-college element-text">{data.committee_member_college}</span>
                            <span className="list-element-city element-text">{data.committee_member_college_city}</span>
                        </li>
                    )
                })) : <Fragment />}
            </ul>
        </motion.div>
    )
}

export default SCommittee;