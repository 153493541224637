import Request from './request';

class OrganizingCommittee extends Request {
  constructor() {
    super();
    this.client = super.constructor.getClient();
  }

  getOrganizingCommittee() {
    return this.client.get("ocommittee/");
  }
}

export default OrganizingCommittee;