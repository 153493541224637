import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import motionConfig from "../../../utils/motion.config";
import ArchivesNav from "./ArchivesNav";
import ArchiveRequest from "../../../Request/archive.request";
import About from "./About";
import "./archives.scss";

const Archives = () => {
    let navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation('common');
    const [currentlySelectedTab, setCurrentlySelectedTab] = useState()
    const [isPreparedToRender, setIsPreparedToRender] = useState(false);
    const archiveRequest = useMemo(() => new ArchiveRequest(), []);

    useMemo(() => {
        let yearsArr = [];

        const year = window.location.pathname.split("/").slice(-1)[0];
        archiveRequest.getArchiveYear().then(res => {
            // eslint-disable-next-line
            res.data.map(element => {
                yearsArr.push(element.archive_year)
            })
            if (yearsArr.includes(parseInt(year))) {
                setIsPreparedToRender(true)
                setCurrentlySelectedTab(<About />)
            } else {
                navigate("/404")
            }
        })
    }, [archiveRequest, navigate]);

    useEffect(() => {
        if (isPreparedToRender) {
            window.scrollTo({ top: 0 })
            document.body.classList.add('subpage');
        }
    }, [isPreparedToRender]);

    return (
        <div className="archives">
            <motion.div
                className='archives-top-bar'
                variants={motionConfig}
                initial="start"
                animate="anim"
                exit="ending"
                transition={{ ease: 'easeInOut' }}
            >
                <h1>{t("archives.menuTitle")} {params.year}</h1>
            </motion.div>
            <div className="archives-main">
                <ArchivesNav setCurrentlySelectedTab={setCurrentlySelectedTab} currentlySelectedArchiveYear={params.year} />
                {currentlySelectedTab}
            </div>
        </div>
    )
}

export default Archives;