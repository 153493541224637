import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import CommentsRequest from '../../../Request/comments.request';
import X from '../../../icons/base/x.svg'
import './addComment.scss';

const AddComment = ({ changeOpacity, closePopup, opacity }) => {
    const maxTextareaLength = 250;
    const { t } = useTranslation('common');
    const [author, setAuthor] = useState('');
    const [comment, setComment] = useState('');
    const [statusText, setStatusText] = useState(null);
    const [statusClass, setStatusClass] = useState("add-comment-status");
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [textareaClass, setTextareaClass] = useState("add-comment-content");
    const [authorClass, setAuthorClass] = useState("add-comment-author");
    const [display, setDisplay] = useState('block');
    const [currentOpacity, setCurrentOpacity] = useState(0);

    useEffect(() => {
        if (opacity === 0) {
            setCurrentOpacity(opacity)
            setTimeout(() => {
                setDisplay("none");
                setStatusClass("add-comment-status");
                setTextareaClass("add-comment-content");
                setAuthorClass("add-comment-author");
                setButtonDisabled(false);
            }, 200);
        }
        else {
            setDisplay("block");
            setTimeout(() => {
                setCurrentOpacity(opacity)
            }, 50)
        }
    }, [opacity])

    const handleSubmit = async () => {
        if (author === '') {
            setAuthorClass("add-comment-author red-border");
        }
        if (comment === '') {
            setTextareaClass("add-comment-content red-border");
        }
        if (comment === '' || author === '') {
            setStatusText(t("comments.request_validation_error"))
            setStatusClass(t("add-comment-status red"))
            setButtonDisabled(true);
            return;
        }
        const currentDate = new Date();

        const hours = currentDate.getHours().toString().padStart(2, '0');
        const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear();

        const formattedDate = `${hours}:${minutes} ${day}.${month}.${year}`;

        const body = {
            "comment": comment,
            "author": author,
            "time": formattedDate
        };

        let request = new CommentsRequest();

        await request.addComment(body).then(() => {
            setStatusText(t("comments.request_success"));
            setStatusClass("add-comment-status green");
            setTimeout(() => {
                changeOpacity();
            }, 1000)
        }).catch(() => {
            setStatusText(t("comments.request_error"));
            setStatusClass("add-comment-status red");
            setButtonDisabled(true);
        })
    }

    return (
        <div className='add-comment' style={{ opacity: currentOpacity, display: display }}>
            <div className='add-comment-background' onClick={() => closePopup()}></div>
            <div className='add-comment-wrapper'>
                <div className='add-comment-top-wrapper'>
                    <span className='add-comment-title'>{t("comments.add_comment")}</span>
                    <button onClick={() => closePopup()} className='add-comment-close-button'>
                        <ReactSVG className='add-comment-close-button-img' src={X} />
                    </button>
                </div>
                <form className="add-comment-form" onSubmit={() => handleSubmit()}>
                    <input onChange={e => {
                        setAuthor(e.target.value);
                        setStatusClass("add-comment-status");
                        setButtonDisabled(false);
                        setAuthorClass("add-comment-author");
                    }} className={authorClass} placeholder={t("comments.author")} />
                    <div className='add-comment-container'>
                        <textarea maxLength={maxTextareaLength} onChange={e => {
                            setComment(e.target.value);
                            setStatusClass("add-comment-status");
                            setButtonDisabled(false);
                            setTextareaClass("add-comment-content");
                        }} className={textareaClass} placeholder={t("comments.add_comment")} />
                        <span className='add-comment-counter'>{`${comment.length}/${maxTextareaLength}`}</span>
                    </div>
                    <button disabled={buttonDisabled} className="add-comment-submit" onClick={() => handleSubmit()} type='button'>{t("comments.add_comment")}</button>
                </form>
                <span className={statusClass}>{statusText}</span>
            </div>
        </div>
    )
}

export default AddComment;