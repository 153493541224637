import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FunFactRequest from "../../../Request/funfact.request";
import LogoFull from '../../../icons/logo/aic-logo-full.png';
import LogoFullWebp from '../../../icons/logo/aic-logo-full.webp';
import LogoFullMobile from '../../../icons/logo/aic-logo-full-mobile.png';
import LogoFullMobileWebp from '../../../icons/logo/aic-logo-full-mobile.webp';
import './logo.scss';

const LogoHomepage = () => {
    const { i18n } = useTranslation('common');
    const request = useMemo(() => new FunFactRequest(), []);
    const [fact, setFact] = useState('');
    const [pl, setPl] = useState(null);
    const [en, setEn] = useState(null);
    const [cs, setCs] = useState(null);
    const [facts, setFacts] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isWindowSmall, setIsWindowSmall] = useState(window.innerWidth < 768 ? true : false)

    const handleLanguage = useCallback((langPl = null, langEn = null, langCs = null) => {
        if (i18n.language === 'pl') {
            setFact(langPl ?? pl);
        } else if (i18n.language === 'en') {
            setFact(langEn ?? en);
        } else if (i18n.language === 'cs') {
            setFact(langCs ?? cs);
        }
    }, [i18n.language, pl, en, cs])

    useEffect(() => {
        request.getFunFacts().then(res => {
            if (res.data.length > 0) {
                const randomFact = Math.floor(Math.random() * res.data.length);
                setFacts(res.data);
                
                setPl(res.data[randomFact].pl)
                setEn(res.data[randomFact].en)
                setCs(res.data[randomFact].cs)
                handleLanguage(res.data[randomFact].pl, res.data[randomFact].en, res.data[randomFact].cs)
            }
            setLoading(false)
        })
        window.addEventListener('resize', () => {
            window.innerWidth < 768 ? setIsWindowSmall(true) : setIsWindowSmall(false);
        })
        // eslint-disable-next-line
    }, [request])


    useEffect(() => {
        setInterval(() => {  
            if (facts) {
                const randomFact = Math.floor(Math.random() * facts.length);
                handleLanguage(facts[randomFact].pl, facts[randomFact].en, facts[randomFact].cs)
            }
        }, 15000)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        handleLanguage()
    }, [i18n.language, handleLanguage])

    return (
        <div className="homepage-logo">
            {isWindowSmall ?
                <>
                    <div className='homepage-logo-element'>
                        <picture>
                            <source srcSet={LogoFullMobileWebp} type="image/webp" />
                            <source srcSet={LogoFullMobile} type="image/png" />
                            <img width={350} alt='logo' src={LogoFullMobile} />
                        </picture>
                    </div>
                </>
                :
                <>
                    <div className='homepage-logo-element'>
                        <picture>
                            <source srcSet={LogoFullWebp} type="image/webp" />
                            <source srcSet={LogoFull} type="image/png" />
                            <img alt='logo' src={LogoFull} />
                        </picture>
                        {loading || !fact ?
                            null
                            :
                            <div className='homepage-logo-element-fact'>
                                <span>{fact}</span>
                            </div>
                        }
                    </div>
                </>
            }
        </div>

    )
}

export default LogoHomepage;