import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import ArchiveRequest from '../../../Request/archive.request';
import motionConfig from '../../../utils/motion.config';
import PaginatedArticles from '../Articles/PaginatedArticles';
import Search from '../Articles/Search';
import ConfigRequest from '../../../Request/config.request';
import './articles.scss';

const Articles = ({ year }) => {
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);
    const [articles, setArticles] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [loader, setLoader] = useState('loader');
    const [articlesPerPage, setArticlesPerPage] = useState(10);
    const [currentShownWithDeps, setCurrentShownWithDeps] = useState([]);
    const [isSearchable, setIsSearchable] = useState(false);
    const [isEmptySearch, setIsEmptySearch] = useState(true);

    let lastArticleIndex = 0;
    let firstArticleIndex = 0;
    let currentShownArticles = [];

    const configRequest = new ConfigRequest();

    if (!loading) {
        lastArticleIndex = currentPage * articlesPerPage;
        firstArticleIndex = lastArticleIndex - articlesPerPage;
        currentShownArticles = articles.slice(firstArticleIndex, lastArticleIndex);
    }

    useEffect(() => {
        window.scrollTo({ top: 0 })
        document.body.classList.add('subpage');
        const request = new ArchiveRequest();
        configRequest.getIsArchivedArticlesVisible().then(visibility => {
            if (visibility.data[0].is_active) {
                request.getArchivedArticles(year).then(res => {
                    setArticles(res.data);
                    setTimeout(() => {
                        setLoading(false)
                    }, 100)
                    setCurrentShownWithDeps(currentShownArticles);
                })
            } else {
                setArticles([]);
            }
        })
        configRequest.getArticlesPerPage().then(res => {
            setArticlesPerPage(parseInt(res.data[0].value));
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const inputHandler = target => {
        if (!target) {
            setIsSearchable(false);
        }
    }

    const onSearchSuccess = (searchedElements) => {
        setCurrentShownWithDeps(searchedElements);
        if (searchedElements.length === 0 || searchedElements.length === articles.length) {
            setIsEmptySearch(true);
            return;
        }
        setIsEmptySearch(false);
        setIsSearchable(true);
    }

    const onPageChange = (page) => {
        if (page === currentPage) return;
        setLoader('loader loading');
        setTimeout(() => {
            setCurrentPage(page);
            setLoader('loader')
        }, 200)
    }

    return (
        <div className='articles'>
            <div className='articles-archive-top-bar'>
                <Search inputHandler={inputHandler} onSearchSuccess={onSearchSuccess} articlesToSearch={articles} />
            </div>
            <ul className='articles-list'>
                {articles.length === 0 ? <span className='articles-list-empty'>{t("articles.noArticles")}</span> : <></>}
                {!isSearchable ? <PaginatedArticles opacity={loading ? 0 : 1} currentPage={currentPage} totalArticles={articles.length} articlesPerPage={articlesPerPage} setCurrentPage={onPageChange} /> : <></>}
                {!isSearchable ? Array.from(currentShownArticles)
                    .sort((a, b) => a.sort_order - b.sort_order)
                    .map((article, key) => {
                        return (
                            <li key={article.id} index={article.id} className={loader}>
                                <motion.div
                                    className="articles-list-article" index={article.id}
                                    variants={motionConfig}
                                    initial="start"
                                    animate="anim"
                                    exit="ending"
                                    transition={{ ease: 'easeInOut' }}
                                >
                                    <Link to={`articles/${article.url_key}`} className='articles-list-link'>
                                        <div className='article-logo'>
                                            <img alt="Aic logo" src={require("../../../icons/logo/aic-logo-main.png")} className='article-logo-img' />
                                        </div>
                                        <div className='article-content'>
                                            <div className='article-title'>{article.title}</div>
                                            <div className="article-description">{article.description.split(/\s+/).slice(0, 19).join(" ")}...</div>
                                        </div>
                                    </Link>
                                </motion.div>
                            </li>
                        )
                    }) : (isEmptySearch ? <p className='articles-list-empty'>{t('articles.empty')}</p> : Array.from(currentShownWithDeps).map((article, key) => {
                        return (
                            <li index={article.id} className={loader}>
                                <motion.div
                                    className="articles-list-article" index={article.id}
                                    variants={motionConfig}
                                    initial="start"
                                    animate="anim"
                                    exit="ending"
                                    transition={{ ease: 'easeInOut' }}
                                >
                                    <Link to={`${article.url_key}`} className='articles-list-link'>
                                        <div className='article-logo'>
                                            <img alt='Aic logo' src={require("../../../icons/logo/aic-logo-main.png")} className='article-logo-img' />
                                        </div>
                                        <div className='article-content'>
                                            <div className='article-title'>{article.title}</div>
                                            <div className="article-description">{article.description}</div>
                                        </div>
                                    </Link>
                                </motion.div>
                            </li>
                        )
                    }))}
                {!isSearchable ? <PaginatedArticles opacity={loading ? 0 : 1} currentPage={currentPage} totalArticles={articles.length} articlesPerPage={articlesPerPage} setCurrentPage={onPageChange} /> : <></>}
            </ul>
        </div>
    )
}

export default Articles;