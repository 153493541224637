import Logo from '../../../icons/logo/aic-logo-full.png';
import './maintenance.scss'

export const Maintenance = () => {
    return(
        <div className='maintenance'>
            <img className='maintenance-logo' alt='logo' src={Logo}/>
            <p className='text text-top'>Currently site is under maintenance.</p>
            <p className='text text-bottom'>We are coming back soon!</p>
        </div>
    )
}