import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './notFound.scss';

const NotFound = () => {
    let navigate = useNavigate();
    const { t } = useTranslation('common');

    useEffect(() => {
        window.scrollTo({ top: 0 })
        document.body.classList.add('subpage');
    }, [])

    return (
        <div className="notfound">
            <div className='notfound-top-bar'>
                <h1>404</h1>
            </div>
            <div className="notfound-main">
                <div className='notfound-wrapper'>
                    <h1 className='notfound-title'>404</h1>
                    <span className='notfound-text'>{t("404.text")}</span>
                    <button className='notfound-button' onClick={() => navigate("/")}>{t("404.button")}</button>
                </div>
            </div>
        </div>
    )
}

export default NotFound;