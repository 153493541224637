import "./paging-button.scss";

const PaginatedArticles = ({ opacity, currentPage, totalArticles, articlesPerPage, setCurrentPage }) => {
    let pages = [];

    for (let i = 1; i <= Math.ceil(totalArticles / articlesPerPage); i++) {
        pages.push(i);
    }

    return (
        <>
        {totalArticles === 0 ? <></> :
            <>
            <div className="paging" style={{ opacity: opacity }}>
                {currentPage !== 1 ? <div className="paging-arrow back" onClick={() => setCurrentPage(currentPage - 1)}></div> : <></>}
                {
                    pages.map((page, index) => {
                        return <div key={index} className={page === currentPage ? "paging-button current" : "paging-button"}><button onClick={() => setCurrentPage(page)} key={index}>{page}</button></div>
                    })
                }
                {currentPage !== pages[pages.length - 1] ? <div className="paging-arrow next" onClick={() => setCurrentPage(currentPage + 1)}></div> : <></>}
            </div>
            </>
        }
        </>
    )
}

export default PaginatedArticles;