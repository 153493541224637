import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LogoNav from '../../Logo/LogoNav';
import ArchiveRequest from '../../../../Request/archive.request';
import Switch from './Switch';
import Translation from './Translation';
import './menu.scss'

const Menu = props => {
    const {
        isMobileView,
        backgroundClick,
        isMenuOpen,
        toggler,
        theme,
        triggerTranslation
    } = props;

    const { t } = useTranslation('common')
    const [opacityBg, setOpacityBg] = useState('opacity-bg hidden none');
    const [years, setYears] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [dropdownClass, setDropdownClass] = useState("archive-dropdown hidden");
    const [ariaExpanded, setAriaExpanded] = useState(false);
    const [display, setDisplay] = useState('none');

    useEffect(() => {
        const request = new ArchiveRequest();
        request.getArchiveYear().then(years => {
            setYears(years.data)
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        });
        window.addEventListener('scroll', handleScrollDropdown)
    }, [])

    const handleScrollDropdown = () => {
        setDropdownClass("archive-dropdown hidden")
        setAriaExpanded(false)
        setTimeout(() => {
            setDisplay('none')
        }, 250);
    }

    const handleOpacityBackground = useCallback(() => {
        if (isMenuOpen) {
            setOpacityBg('opacity-bg hidden');
            setTimeout(() => {
                setOpacityBg('opacity-bg')
            }, 100)
        } else {
            setOpacityBg('opacity-bg hidden')
            setTimeout(() => {
                setOpacityBg('opacity-bg hidden none')
            }, 400)
        }
    }, [isMenuOpen]);

    useEffect(() => {
        handleOpacityBackground()
    }, [isMenuOpen, handleOpacityBackground])

    const handleArchiveOpen = () => {
        if (dropdownClass === "archive-dropdown") {
            setDropdownClass("archive-dropdown hidden")
            setAriaExpanded(false)
            setTimeout(() => {
                setDisplay('none')
            }, 250);
        } else if (dropdownClass !== "archive-dropdown" && years.length) {
            setDisplay('flex')
            setTimeout(() => {
                setDropdownClass("archive-dropdown")
                setAriaExpanded(true)
            }, 20);
        }
    }

    return (
        <div className={isMobileView ? "container mobile" : "container"}>
            <Link key={'1-nav-link'} className='menu-logo' to="/"><LogoNav /></Link>
            <div onClick={backgroundClick} className={opacityBg}></div>
            <ul className={isMenuOpen ? "menu open" : "menu"}>
                <li key={'1-nav'}><Link key={'2-nav-link'} onClick={backgroundClick} className='menu-item' to="/articles">{t('articles.menuTitle')}</Link></li>
                <li key={'2-nav'}><Link key={'3-nav-link'} onClick={backgroundClick} className='menu-item' to="/workshop-program">{t('workshopProgram.menuTitle')}</Link></li>
                <li key={'3-nav'}><Link key={'4-nav-link'} onClick={backgroundClick} className='menu-item' to="/scientific-committee">{t('scientificCommittee.menuTitle')}</Link></li>
                <li key={'4-nav'}><Link key={'5-nav-link'} onClick={backgroundClick} className='menu-item' to="/organizing-committee">{t('organizingCommittee.menuTitle')}</Link></li>
                <li key={'5-nav'}><Link key={'6-nav-link'} onClick={backgroundClick} className='menu-item' to="/contact">{t('contact.menuTitle')}</Link></li>
                <li key={'6-nav'}>
                    <div key={'6-nav'} onClick={handleArchiveOpen} className='archive menu-item'>
                        <span expanded={ariaExpanded.toString()} className='archive-title'>{t('archives.menuTitle')}</span>
                        <div className={dropdownClass} style={{ display: display }}>
                            {isLoading ? null : years.map(year => {
                                return (
                                    <Link className='archive-dropdown-link' key={year.archive_year} to={'archive/' + year.archive_year}>{year.archive_year}</Link>
                                )
                            })}
                        </div>
                    </div>
                </li>
                {window.innerWidth < 891 ? <li className={'options'}>
                    <Switch onToggle={toggler} theme={theme} />
                    <Translation triggerTranslation={triggerTranslation} />
                </li> : null}
            </ul>
        </div>
    )
}

export default Menu;